{
  "ABOUT_TITLE": "Korki - Twoja aplikacja do prowadzenia lekcji i zarządzania firmą edukacyjną.",
  "ABOUT_DESC": "Jeśli prowadzisz szkołę, organizujesz kursy lub oferujesz indywidualne lekcje, nasza aplikacja zapewni Ci pełną kontrolę nad każdym aspektem Twojej działalności. Dzięki intuicyjnemu interfejsowi oraz bogatym funkcjonalnościom, \"Korki\" to idealne rozwiązanie dla każdej placówki edukacyjnej.",
  "HEAD_TITLE": "Korki",
  "SPAN_LOADING": "Ładowanie...",
  "H1_TITLE": "Donutize",
  "NAV_HOME": "Korki",
  "NAV_ABOUT": "O aplikacji",
  "NAV_FEATURE": "Rozwiązanie",
  "NAV_PRICING": "Cennik",
  "NAV_CONTACT": "Kontakt",
  "BTN_LOGIN": "Zaloguj",
  "BTN_SUBMIT": "Wyślij",
  "BTN_TRY": "Wypróbuj",
  "BTN_TRY_FOR_FREE": "Wypróbuj za darmo",
  "BTN_LEARN_MORE": "Dowiedz się więcej",
  "BTN_CONTACT_US": "Skontaktuj się z nami",
  "ABOUT_H5": "O aplikacji",
  "ABOUT_H1": "Aplikacja \"Korki\" to nowoczesne narzędzie",
  "ABOUT_P": "zaprojektowane z myślą o kompleksowym zarządzaniu firmą edukacyjną (nawet dla jednoosobowych!). Jeśli prowadzisz szkołę, organizujesz kursy lub oferujesz indywidualne lekcje, nasza aplikacja zapewni Ci pełną kontrolę nad każdym aspektem Twojej działalności. Dzięki intuicyjnemu interfejsowi oraz bogatym funkcjonalnościom, \"Korki\" to idealne rozwiązanie dla każdej placówki edukacyjnej. Oferujemy:",
  "ABOUT_LI1": "Zarządzanie pracownikami: Tworzenie profili pracowników z dedykowanymi rolami i określanymi umiejętności.",
  "ABOUT_LI2": "Obsługa kursantów: Dodawanie uczniów do konkretnych nauczycieli, co umożliwia lepszą organizację pracy i kontrolę nad swoją firmą.",
  "ABOUT_LI3": "Kalendarz zajęć: Planowanie lekcji wraz z możliwością dodawania notatek dotyczących przebiegu lekcji.",
  "ABOUT_LI4": "Moduł dokumentacji: Tworzenie i zarządzanie dokumentami i plikami ułatwiającymi codzienną pracę.",
  "ABOUT_LI5": "Zarządzanie uczniami: Centralne zarządzanie danymi kursantów.",
  "ABOUT_LI6": "Panel dla ucznia i rodzica: Dedykowane miejsce, gdzie uczniowie i ich rodzice mogą śledzić postępy i informacje o zajęciach.",
  "ABOUT_LI7": "Dedykowany moduł raportowy: Dedykowane miejsce, gdzie możemy wygenerować raporty ułatwiające zarządzanie firmą",
  "ABOUT_LI8": "Komunikacja wewnętrzna: Możliwość dodawania notatek i komunikatów dla pracowników, co usprawnia komunikację w zespole.",
  "ABOUT_LI9": "Responsywność: Aplikacja działa na urządzenia mobilnych jak i tabletach",
  "FEATURES_H5": "Co fajnego",
  "FEATURES_H1": "Możliwości aplikacji",
  "FEATURES_SIMPLICITY_H5": "Uczniowie / Kursanci",
  "FEATURES_SIMPLICITY_P": "Główną funkcjonalnością aplikacji jest dodawanie kursantów. Uczniowie przypisani są do konkretnego nauczyciela przez co łatwo możemy organizować lekcję",
  "FEATURES_GAMIFICATION_H5": "Pracownicy",
  "FEATURES_GAMIFICATION_P": "Administrator aplikacji ma możliwość dodawania pracowników wraz z jego umiejętnościami, które są definiowane globalnie dla całej organizacji.",
  "FEATURES_ON_DEMAND_H5": "Zajęcia / Kalendarz",
  "FEATURES_ON_DEMAND_P": "Najbardziej rozbudowanym widokiem w aplikacji jest sekcja „Zajęcia”. Tutaj mamy możliwość dodawania zajęć dla uczniów oraz podglądu kalendarza nauczyciela.",
  "FEATURES_CONVENIENT_WORK_H5": "Płatności",
  "FEATURES_CONVENIENT_WORK_P": "Aplikacja oferuje funkcję zarządzania płatnościami, umożliwiając administratorowi wprowadzanie informacji o wpłatach dokonanych przez uczniów. Płatności są realizowane poza systemem, a w systemie administrator wpisuje kwotę wpłaconą przez ucznia.",
  "FEATURES_CLEAR_SUBSCRIPTIONS_H5": "Opcję zarządzania organizacją",
  "FEATURES_CLEAR_SUBSCRIPTIONS_P": "Aplikacja pozwala w dedykowanej sekcji konfigurować ustawienia firmy, dodawać umiejętności / kompetycje nauczyciela, dodawać pliki oraz zarządzać pracownikami i uczniami",
  "FEATURES_STAY_UPDATED_H5": "Raporty",
  "FEATURES_STAY_UPDATED_P": "Raporty to oddzielna bardzo rozbudowana część systemu. Mamy aż 11 różnych raportów / dokumentów ułatwiających funkcjonowanie firmy.",
  "PROCESS_H5": "Jak zacząć",
  "PROCESS_H1": "W 3 prostych krokach",
  "PROCESS_REGISTER_H5": "Zarejestruj swoją organizację",
  "PROCESS_REGISTER_P": "Zarejestruj swój profil i ciesz się darmową wersją aplikacji.",
  "PROCESS_CONFIGURE_H5": "Skonfiguruj system",
  "PROCESS_CONFIGURE_P": "Dodaj uczniów, pracowników i przedmioty, których będziesz oferował z swojej ofercie.",
  "PROCESS_OBSERVE_H5": "Obserwuj pozytywne efekty",
  "PROCESS_OBSERVE_P": "Dodaj pracowników, uczniów oraz lekcję i zacznij się cieszyć swoją firmą.",
  "PRICING_H5": "Cennik",
  "PRICING_H1": "Wybierz swój plan",
  "PRICING_SWITCH_MONTHLY": "Miesięczny",
  "PRICING_SWITCH_YEARLY": "Roczny",
  "PRICING_PRICE": "{price}zł",
  "PRICING_PER_MONTH": "/miesiąc*",
  "PRICING_PER_YEAR": "/rok*",
  "PRICING_USERS_AMOUNT": "do {amount} lekcji",
  "PRICING_DIET_H4": "Pakiet FREE",
  "PRICING_DIET_SPAN": "Limit 300 lekcji",
  "PRICING_DIET_ADVANTAGE1": "nielimitowana ilość pracowników",
  "PRICING_DIET_ADVANTAGE2": "nielimitowana ilość uczniów",
  "PRICING_DIET_ADVANTAGE3": "dostęp do raportów",
  "PRICING_JAM_H4": "Pakiet PREMIUM",
  "PRICING_JAM_SPAN": "Nielimitowana ilość lekcji",
  "PRICING_JAM_ADVANTAGE1": "nielimitowana ilość pracowników",
  "PRICING_JAM_ADVANTAGE2": "nielimitowana ilość uczniów",
  "PRICING_JAM_ADVANTAGE3": "dostęp do raportów",
  "PRICING_CREAM_H4": "Pakiet PREMIUM+",
  "PRICING_CREAM_SPAN": "Nielimitowana ilość lekcji plus dodatkowe funkcjonalności",
  "PRICING_CREAM_ADVANTAGE1": "nielimitowana ilość pracowników",
  "PRICING_CREAM_ADVANTAGE2": "nielimitowana ilość uczniów",
  "PRICING_CREAM_ADVANTAGE3": "dostęp do raportów",
  "PRICING_CREAM_ADVANTAGE4": "dodatkowe funkcjonalności",
  "PRICING_BOTTOM_P1": "*Ceny nie zawierają VAT",
  "PRICING_BOTTOM_P2": "Nie znalazłeś planu dla siebie? Masz większą firmę? Nic straconego - skontaktuj się z nami a przygotujemy dla Ciebie specjalną ofertę.",
  "CONTACT_H5": "Kontakt",
  "CONTACT_H1": "Napisz do nas",
  "CONTACT_P": "Jeżeli masz jakiekolwiek pytania to napisz do nas. Odpowiadamy zazwyczaj w ciągu 24h.",
  "CONTACT_INPUT_NAME": "Imię i Nazwisko",
  "CONTACT_INPUT_EMAIL": "Email",
  "CONTACT_INPUT_TITLE": "Tytuł",
  "CONTACT_INPUT_MESSAGE": "Wiadomość",
  "FOOTER_ADDRESS_H4": "Adres",
  "FOOTER_SHORTCUTS_H4": "Skróty",
  "FOOTER_SHORTCUTS_ABOUT": "O aplikacji",
  "FOOTER_SHORTCUTS_CONTACT": "Skontaktuj się z nami",
  "FOOTER_SHORTCUTS_PRIVACY": "Polityka prywatności",
  "FOOTER_SHORTCUTS_RULES": "Regulamin",
  "FOOTER_SHORTCUTS_PRICING": "Plany cenowe"
}

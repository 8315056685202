//import * as en from './i18n/en.json';
import * as pl from './i18n/pl.json';

/*const objWithTranslations = {
    en, pl
}*/
const objWithTranslations = {
    pl
}
let activeLocale = "pl";
let translations: { [key: string]: string } = {};

function browserLocales(languageCodeOnly = false) {
    return navigator.languages.map((locale) =>
        languageCodeOnly ? locale.split("-")[0] : locale,
    );
}

const localStorageLang = localStorage.getItem('lang');

if (localStorageLang && localStorageLang !== '') {
    activeLocale = localStorageLang;
    translations = objWithTranslations[activeLocale];
} else {
    if (browserLocales(true).includes(activeLocale)) {
        translations = objWithTranslations[activeLocale];
    } else {
        //translations = en;
    }
}

setTimeout(() => {
    const imgFlagEl: HTMLImageElement = document.querySelector(`.flag img.${activeLocale}`);
    imgFlagEl.classList.replace('hidden', 'active');
}, 1000)


function translatePage() {
    document
        .querySelectorAll("[data-i18n-key]")
        .forEach(translateElement);
}

function translateElement(element: HTMLElement) {
    const key = element.getAttribute("data-i18n-key");
    const translation = translations[key];
    const options = JSON.parse(
        element.getAttribute("data-i18n-opt")
    );
    element.innerText = options
        ? interpolate(translation, options)
        : translation;
}

function interpolate(message: string, interpolations: { [key: string]: string }) {
    return Object.keys(interpolations).reduce(
        (interpolated, key) =>
            interpolated.replace(
                new RegExp(`{\s*${key}\s*}`, "g"),
                interpolations[key],
            ),
        message,
    );
}

translatePage();

document.querySelector('.flag').addEventListener('click', () => {
    activeLocale = activeLocale === 'en' ? 'pl' : 'en';
    translations = objWithTranslations[activeLocale];

    localStorage.setItem('lang', activeLocale);

    const imgEn: HTMLImageElement = document.querySelector('.flag img.en');
    const imgPl: HTMLImageElement = document.querySelector('.flag img.pl');

    if (activeLocale === 'en') {
        imgEn.classList.replace('hidden', 'active');
        imgPl.classList.replace('active', 'hidden');
    } else if (activeLocale === 'pl') {
        imgPl.classList.replace('hidden', 'active');
        imgEn.classList.replace('active', 'hidden');
    }

    translatePage();
})